@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Istok+Web:ital,wght@0,400;0,700;1,400;1,700&display=swap");

:root {
  --dark-color: #212121;
  --light-color: #f0f0f0;
  --second-color: #ffffff;
  --second-dark-color: #4f4f4f;
  --gray: #f2f2f2;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  height: 100vh;
  overflow: hidden;
}

body {
  overflow: auto;
  overflow-x: hidden;
  font-family: "Inter";
  transition: background 0.3s;
  height: 100vh;
  background-color: var(--light-color);

}

@media (max-width: 670px) {
  html {
    overflow-y: auto;
  }

  .twitch-stream {
    display: none;
  }

  html:has(.burgerMenu) {
    overflow: hidden !important;
  }
}

html:has(.popup-overlay) {
  overflow: hidden !important;
}

@font-face {
  font-family: "Nico Moji";
  src: url("/src/assets/fonts/Nico-Moji.ttf.woff");
}

.ant-message {
  z-index: 9999;
  color: #fff !important;

  /* right: 0 !important;
  bottom: 0 !important;
  left: initial !important;
  transform: none !important;
  top: initial !important; */

}

.ant-message-notice-content {
  background-color: #12478cc6 !important;
}

#tc-widget-root {
  position: relative;
  z-index: 10000;
}