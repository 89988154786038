.root {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
  display: flex;
  justify-content: center;
  width: 100vw;
  height: 100vh;
}

.overlay {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9998;
  display: flex;
  background: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(8px);
  // animation: smoothBlur 0.3s linear;
}

.modal {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
  overflow: visible;
}

.container {
  display: flex;
  width: 550px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;

  background: white;
  overflow: visible;
  background-color: white;
  padding: 24px;
  position: relative;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2); /* Тень */

  z-index: 10000;

  .cross {
    cursor: pointer;
    align-self: end;
    height: 24px;
    width: 24px;
  }
}

.content {
  position: relative;
  display: flex;
  justify-content: center;
  border-radius: 8px;
  width: 100%;
  overflow: visible;
  height: fit-content;
  // max-height: 80vh;
}

body.darkMode {
  .container {
    background-color: #212121;
  }
  .cross {
    filter: invert(1);
  }
}

@media (max-width: 780px) {
  .container {
    width: 100vw;
    padding: 32px 16px;
    height: 90vh;
    gap: 16px;
    overflow-y: scroll;
  }
}
