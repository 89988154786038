.AboutProject {
  max-width: 500px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  h2 {
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    text-transform: uppercase;
    width: 100%;
  }
  font-size: 18px;
}
.coin {
  display: inline-block;
  width: 18px;
  height: 18px;

  background-image: url('../../../assets/icons/coin.svg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
