.accountInfoPopup {
  display: flex;
  flex-direction: column;

  .info {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    gap: 10px;
    p {
      color: #333333;
      font-size: 12px;
      transition: all 0.3s;
      text-align: center;
    }
  }

  .dropdownButton {
    display: flex;
    gap: 10px;
    justify-content: center;
    border-radius: 99px;
    background-color: #222222;
    box-shadow: none;
    color: #ffffff;
    &::after {
      height: 24px;
      width: 24px;
      transition: all 0.3s;

      content: url("../../../assets/icons/TickerDown.svg");
    }
    &:hover {
      scale: 1.05;
    }
  }

  .dropdownButton.true {
    &::after {
      margin-top: -3px;
      transform: rotate(180deg);
    }
    &:hover {
      background-color: #fff !important;
    }
  }
  .actionList-image {
    height: 24px;
    width: 24px;
    transition: all 0.3s;
  }
  .imageCopy {
    background-image: url("../../../assets/icons/copyIcon-dark.svg");
  }

  .imageDisable {
    background-image: url("../../../assets/icons/ExitIcon-dark.svg");
  }
  .actionList {
    position: absolute;
    margin-top: 60px;
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    justify-content: center;
    align-items: center;
    box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    width: 236px;

    li {
      border-radius: 8px;
      color: #333333;
      font-size: 15px;
      display: flex;
      width: 100%;
      padding: 12px 18px;

      justify-content: start;
      align-items: center;
      gap: 6px;

      cursor: pointer;
      transition: all 0.3s;
      &:hover {
        color: #fff;
        background-color: #1b6cd9;
        .imageCopy {
          background-image: url("../../../assets/icons/copyIcon.svg");
        }

        .imageDisable {
          background-image: url("../../../assets/icons/ExitIcon.svg");
        }
      }
    }
  }
  button {
    font-size: 14px;
    padding: 14px;
    width: 236px;
    transition: all 0.3s;
    background-color: #ffffff;
    border-radius: 8px;
    color: #333333;
    box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.2);
    &:hover {
      color: #fff;
      background-color: #1b6cd9 !important;
    }
  }

  @media (max-width: 780px) {
    width: 100%;
    position: relative;

    button {
      width: 100%;
    }
    .actionList {
      width: 100%;
    }
  }
}

body.darkMode {
  .accountInfoPopup {
    button {
      &:hover {
        color: #fff;
        background-color: #1b6cd9;
      }
    }
    .info {
      p {
        color: #f2f2f2;
      }
    }
    .imageCopy {
      background-image: url("../../../assets/icons/copyIcon.svg");
    }

    .imageDisable {
      background-image: url("../../../assets/icons/ExitIcon.svg");
    }
    .actionList {
      background-color: #4f4f4f;
      box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.2);
      li {
        color: #f2f2f2;
      }
    }
    button {
      background-color: #4f4f4f;
      color: #f2f2f2;
    }
  }
}
