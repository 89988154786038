.App {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  justify-content: space-between;
  padding: 48px 0 0 0;
  max-width: 1920px;
  margin: 0 auto;

  @media (max-width: 1280px) {
    padding: 20px;
    gap: 60px;
  }

  @media (max-width: 780px) {
    padding: 16px 16px 60px 16px;
  }
}
