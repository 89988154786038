.counter {
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: start;
  align-items: start;
  .my {
    width: fit-content;
  }
  .row {
    display: flex;
    gap: 4px;
    align-items: center;
  }
  .coin {
    width: 18px;
    height: 18px;

    background-image: url('../../assets/icons/coin.svg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }

  @media (max-width: 780px) {
    flex-wrap: wrap;
    gap: 20px;
  }
}
