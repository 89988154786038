.transaction-form-container {
  display: flex;
  flex-direction: column;

  gap: 30px;

  align-items: center;
  text-align: center;

  .amount-buttons {
    display: flex;
    gap: 10px;
    flex-direction: column;
    align-items: center;
    button {
      width: 220px;
      border: none;
      max-height: 48px;
      transition: all 0.3s;
      height: 48px;
      box-sizing: border-box;
      font-size: 16px;
      display: flex;
      justify-content: flex-start;
      gap: 10px;
      align-items: center;
      font-family: 'Inter';
      &::before {
        margin-top: 7px;
        transition: all 0.3s;
        content: url('../../../assets/icons/BaseRadioInput.svg');
      }
      &:hover {
        border: 1px solid #2f80ed;
      }
    }
    .select {
      border: 2px solid #2f80ed;
      &::before {
        content: url('../../../assets/icons/BaseRadioInputSelect.svg');
      }
    }
  }
  .send-button {
    border-radius: 50px;
    background-color: #2f80ed;
    color: #fff;
    box-shadow: 0 4px 4px 0 rgba(47, 128, 237, 0.3),
      0 8px 12px 6px rgba(47, 128, 237, 0.2);
    height: 60px;
    width: 220px;
    border: none;
    text-transform: uppercase;
    font-weight: 600;
    font-family: 'Inter';
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    //&::after {
    //  margin-top: 5px;
    //  content: url("../../assets/icons/luckyIcon.svg");
    //}
  }
}

.popup {
  z-index: 99999;
}

body.darkMode {
  .transaction-form-container {
    .amount-buttons {
      button {
        background-color: #212121;
        color: #fff;
        &::before {
          content: url('../../../assets/icons/BaseRadioInputDark.svg');
        }
        &:hover {
          border: none;
          background-color: #212121;
        }
      }
      .select {
        background-color: #333333;
        &::before {
          content: url('../../../assets/icons/BaseRadioInputSelectDark.svg');
        }
      }
    }
  }
}
