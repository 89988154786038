.Pagination_root {
  display: flex;
  justify-content: center;
  gap: 10px;

  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  text-align: center;

  button {
    width: 30px;
    height: 30px;
    background: transparent;
    border: none;
    cursor: pointer;

    &.active {
      width: 30px;
      height: 30px;
      border-radius: 99px;
      border: 1px solid #2f80ed;
    }
  }
}

body.darkMode {
  .Pagination_root {
    button {
      color: white;
    }
  }
}
