.UnlockingForm {
  display: flex;
  flex-direction: column;
  gap: 30px;
  h2 {
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    text-transform: uppercase;
    width: 100%;
  }
  p {
    font-size: 16px;
  }
  form {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  .input {
    display: flex;
    flex-direction: column;
    gap: 10px;
    input {
      height: 47px;
      border-radius: 8px;
      font-family: 'inter';
      text-align: center;
    }
    label {
      font-size: 18px;
    }
  }
  button {
    width: 190px;
    height: 60px;
    border-radius: 50px;
    color: #fff;
    margin: 0 auto;
    background-color: #2f80ed;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    font-family: 'inter';
    text-transform: uppercase;
    font-weight: 600;
    margin-top: 20px;
    box-shadow: 0 4px 4px 0 rgba(47, 128, 237, 0.3),
      0 8px 12px 6px rgba(47, 128, 237, 0.2);
    &::after {
      margin-top: 7px;
      content: url('../../../assets/icons/telegramaIcon.svg');
    }
  }
  .privacy {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 12px;
    padding-left: 10%;
    input {
      min-height: 16px;
      min-width: 16px;
    }
    a {
      text-decoration: underline;
    }
  }

  .error {
    color: red;
    font-size: 14px;
    text-align: center;
  }
}

body.darkMode {
  color: #f2f2f2;
  input {
    background-color: #333;
    border: none;
    color: #fff;
    &:hover {
      background-color: #333;
    }
    &:focus {
      box-shadow: none;
      background-color: #333;
    }
    &::placeholder {
      color: #bdbdbd;
    }
  }
  .privacy {
    color: #f2f2f2;
  }
}
