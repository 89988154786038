.AboutProject {
  display: flex;
  flex-direction: column;
  gap: 30px;
  h2 {
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    text-transform: uppercase;
    width: 100%;
  }
  span {
    color: #2f80ed;
    font-weight: bold;
  }
}
