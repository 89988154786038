.ParallaxText_root {
  transform: rotateZ(0);
  position: relative;

  .text {
    position: absolute;
    font-size: 10vw;
    color: #fff;
    font-family: "Montserrat", sans-serif;
    left: 50%;

    transform: translate(-50%, -50%);
    mix-blend-mode: screen;
    letter-spacing: 1rem;
    overflow: hidden;

    @for $i from 0 through 40 {
      $key: $i + 1;
      &:nth-child(#{$key}) {
        $row: floor($i / 20);
        color: rgba(0, random(20) + 90, 234, 1);
        clip-path: polygon(
          $i * 5% - $row * 100% - random(2) $row * 50% - random(5),
          $key * 5% - $row * 100% + random(5) $row * 50% - random(30),
          $key * 5% - $row * 100% + random(5) $row * 50% + 50% + random(5),
          $i * 5% - $row * 100% - random(2) $row * 50% + 50% + random(30)
        );
        filter: blur(#{random(20) - 14}px);
        .text_inner1 {
          animation: vertical
            random(1000) +
            700ms
            random(100) +
            0ms
            ease-in-out
            infinite
            alternate;
        }
        .text_inner2 {
          animation: horizontal
            random(1000) +
            700ms
            random(100) +
            0ms
            ease-in-out
            infinite
            alternate;
        }
      }
    }
  }

  &.small {
    .text {
      font-size: 6vw;
      letter-spacing: 0.8rem;
    }
  }

  @media (max-width: 780px) {
    .text {
      letter-spacing: 0.4em;
    }
  }
}

@keyframes horizontal {
  0% {
    transform: translateX(6px);
  }
  100% {
    transform: translateX(-6px);
  }
}

@keyframes vertical {
  0% {
    transform: translateY(3px);
  }
  100% {
    transform: translateY(-3px);
  }
}
