.ChangeWallet {
  display: flex;
  flex-direction: column;
  gap: 30px;

  align-items: center;
  ul {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    li {
      padding: 12px;
      border-radius: 8px;
      // width: 223px;
      display: flex;
      justify-content: center;
      box-sizing: border-box;
      flex-direction: column;
      cursor: pointer;
      align-items: center;
      &:hover {
        padding: 10px;

        border: 2px solid #1b6cd9;
      }

      &.active {
        padding: 10px;
        border: 2px solid #1b6cd9;
        cursor: default;
      }
    }

    .sub_title {
      color: #333;
      font-size: 12px;
    }
  }
  button {
    color: #fff;
    // width: 223px;
    height: 50px;
    border-radius: 40px;
    background-color: #333;
  }
  .ton-button {
    // width: 223px !important;
    .go157313249 {
      // width: 223px !important;
      z-index: 11092301 !important;
      position: relative;
    }
  }
}

body.darkMode {
  .sub_title {
    color: #fff;
  }
}
