.Attempt_root {
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 40px;
  align-items: center;

  height: 100%;
  justify-content: space-between;

  .Attempt_root__logo {
    display: inline;
  }

  &.noAuth {
    height: auto;
    padding-bottom: 48px;
  }
}

.bottom-side {
  display: flex;
  justify-content: start;
  width: 100%;
  padding: 22px 48px;
  background-color: #dcdcdc;

  gap: 25px;

  .TwitchPlayer {
    min-width: calc(40% - 40px);
    height: fit-content;
    border-radius: 14px;
    // overflow: hidden;
    min-height: 363px;
    aspect-ratio: 16 / 9;
    max-width: 592px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    align-items: center;

    .header {
      display: flex;
      align-items: center;
      gap: 8px;
    }
    .icon {
      min-width: 24px;
      min-height: 24px;
      height: 24px;
      width: 24px;
      object-fit: contain;
      background-position: center;
      background-repeat: no-repeat;
      display: flex;

      background-image: url('../../assets/icons/play-icon.svg');
      svg {
        height: 24px;
        width: 24px;
      }
    }
  }

  @media (max-width: 1280px) {
    flex-direction: column;
    gap: 60px;
    //background-color: transparent;
    padding: 16px;
    width: 100vw;
    height: fit-content;

    .TwitchPlayer {
      min-width: inherit;
      min-height: 440px;
      height: 100%;
      max-width: 100%;
    }
  }

  @media (max-width: 590px) {
    gap: 32px;

    .TwitchPlayer {
      min-width: 100%;
    }
  }
}

.ton-buttons {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  width: fit-content;

  .ton-button {
    width: 100%;

    .go157313249 {
      width: 100% !important;
      z-index: 11092301 !important;
      position: relative;
    }
    button {
      display: flex;
      border: 2px solid #2f80ed;
      justify-content: center;
      flex-direction: row-reverse;
      background-color: #2f80ed;
      padding: 10px 60px;
      transition: all 0.3s;
      font-size: 14px;
      border-radius: 40px;
      color: #fff;
      cursor: pointer;

      font-family: 'Inter';
      width: 100%;
      font-weight: 600;
      align-items: center;
      text-transform: uppercase;
      height: 50px;

      line-height: 10px;
      &:hover {
        transform: none;
        background-color: transparent;
        color: #2f80ed;
        * {
          color: #2f80ed;
          fill: #2f80ed;
        }
      }
    }
  }

  .buttonUnlockingApply {
    button {
      cursor: pointer;

      font-size: 14px;
      font-weight: 700;
      line-height: 20.15px;
      padding: 10px;
      color: #2f80ed !important;
      border: none;
      background: transparent;
    }
  }
}

body.darkMode {
  .bottom-side {
    background-color: #2b2b2b;
  }

  @media (max-width: 1280px) {
    .bottom-side {
      //background-color: transparent;
    }
  }
}
