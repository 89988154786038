.password-table-container {
  width: 100%;
  justify-content: start;
  align-items: start;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
  grid-auto-rows: auto;
  gap: 20px;

  .item {
    display: flex;
    align-items: center;
    text-align: center;
    padding-left: 12px;
    border-left: 1px solid #f2f2f2;
    gap: 12px;

    p {
      text-wrap: nowrap; // Можно убрать, так как это свойство не существует.
      width: fit-content;

      /* Добавляем свойства для обрезки текста */
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  .yes,
  .not {
    min-width: 24px;
    min-height: 24px;
    object-fit: contain;
    background-position: center;
    background-repeat: no-repeat;
  }
  .yes {
    height: 24px;
    width: 24px;
    background-image: url('../../../assets/icons/yes.svg');
  }
  .not {
    height: 24px;
    width: 24px;
    background-image: url('../../../assets/icons/not.svg');
  }

  @media (max-width: 1280px) {
    .item {
      border-left: 1px solid #ddd;
    }
  }
}

body.darkMode {
  .password-table-container {
    th {
      background-color: #333;
      color: #fff;
      transition: all 0.3s;
    }
    td {
      background-color: #333;
      transition: all 0.3s;
      color: #fff;
      border: none;

      .ant-empty-description {
        color: #fff !important;
        transition: all 0.3s;
      }
    }
  }
  :where(.css-dev-only-do-not-override-djtmh8).ant-table-wrapper
    .ant-table-tbody
    .ant-table-row
    > .ant-table-cell-row-hover {
    background: #868686;
    color: #fff;
  }
}
