.ThreeDotsWave_root {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f0f0f0;
  z-index: 9999;
  position: absolute;
  top: 0;
}

.ThreeDotsWave_root_styleContainer {
  position: relative;
  width: 50px;
  height: 50px;
}
.ThreeDotsWave_root_styleSpan {
  display: block;
  width: 50px;
  height: 50px;
  border: 7px solid #eee;
  border-top: 7px solid #2d3134;
  border-radius: 50%;
  box-sizing: "border-box";
  position: "absolute";
  top: 0;
  left: 0;
}

body.darkMode {
  .ThreeDotsWave_root {
    background-color: #212121;
  }
}
