.Header_root_notAuth {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: start;
  width: 100%;
  padding: 0 48px;

  z-index: 10;

  .burgerButton {
    display: none;
  }

  @media (max-width: 1280px) {
    padding: 0;

    .img {
      grid-area: logo;
      max-width: 260px;
      min-width: 160px;
      width: 100%;
    }

    .Header_root__rightWrapper {
      grid-area: rightWrapper;
    }

    .Header_root__leftWrapper {
      grid-area: leftWrapper;
    }

    .toggleAndBurger {
      display: flex;
      gap: 20px;
    }

    .burgerButton {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      width: 28px;
      height: auto;
      object-fit: cover;
      background-image: url('../../assets/icons/burger.svg');
      background-repeat: no-repeat;
    }
    .Header__toggle-svg {
    }
  }

  @media (max-width: 780px) {
    flex-wrap: wrap;
    gap: 20px;
  }
}

.Header_root {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: start;
  width: 100%;
  padding: 0 48px;

  z-index: 10;

  .burgerButton {
    display: none;
  }

  .toggleAndBurger {
    display: flex;
    gap: 20px;

    .sound-off-button {
      min-width: 24px;
      min-height: 24px;
      height: 24px;
      width: 24px;
      object-fit: contain;
      background-position: center;
      background-repeat: no-repeat;
      display: flex;
      cursor: pointer;

      background-image: url('../../assets/icons/sound-on.svg');

      &.muted {
        background-image: url('../../assets/icons/sound-off.svg');
      }
    }
  }

  @media (max-width: 1280px) {
    padding: 0;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
      'logo rightWrapper'
      'leftWrapper leftWrapper';
    gap: 10px;
    align-items: center;

    .img {
      grid-area: logo;
      max-width: 260px;
      min-width: 160px;
      width: 100%;
    }

    .Header_root__rightWrapper {
      grid-area: rightWrapper;
    }

    .Header_root__leftWrapper {
      grid-area: leftWrapper;
    }

    .burgerButton {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      width: 28px;
      height: auto;
      object-fit: cover;
      background-image: url('../../assets/icons/burger.svg');
      background-repeat: no-repeat;
    }
    .Header__toggle-svg {
    }
  }

  @media (max-width: 780px) {
    flex-wrap: wrap;
    gap: 20px;
  }
}

.Header_root__leftWrapper {
  display: flex;
  gap: 20px;

  .variables {
    display: flex;
    gap: 20px;

    &.full {
      display: grid;
    }
  }

  .price-wrapper {
    display: flex;
    gap: 20px;
    width: 100%;
  }

  .price {
    min-width: fit-content;
    width: 100%;
    border: 2px solid var(--second-color);
    display: flex;
    padding: 10px 20px;
    transition: border 0.3s;
    transition: color 0.3s;
    border-radius: 8px;
    flex-direction: column;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);
  }
  .price-value,
  .balance-value {
    font-weight: bold;
  }

  @media (max-width: 1280px) {
    .variables {
      gap: 10px;
    }

    .price-wrapper {
      width: inherit;
      gap: 10px;
    }

    .counter {
      flex-direction: row;
      gap: 10px;
    }

    .price {
      width: max-content;
      padding: 10px 15px;
    }
  }

  @media (max-width: 780px) {
    .variables {
      flex-wrap: wrap;
      gap: 8px;
    }

    .price {
      font-size: 14px;
    }
  }
}

.Header_root__rightWrapper {
  display: flex;
  gap: 20px;
  justify-content: flex-end;
}

.toggle-theme {
  .btn {
    background-color: #bdbdbd;
    width: 48px;
    height: 24px;
    border-radius: 12px;
    padding: 0 1px;

    box-shadow: 0px 1px 1px 0px #00000040 inset;

    position: relative;

    display: flex;
    align-items: center;

    cursor: pointer;
  }
  .btn__indicator {
    background-color: #fff;
    width: 22px;
    height: 22px;
    border-radius: 50%;

    position: absolute;

    box-shadow: 0px 1px 1px 0px #0006247a;

    transition: transform 0.3s ease;
  }
  .btn__icon-container {
    width: 100%;
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  .btn__icon {
    height: 16px;
    width: 16px;
    background-position: center;
    transition: background-image 0.3s;
    background-image: url('../../assets/icons/sunIcon.svg');
    background-size: 16px;
  }

  .btn__icon.animated {
    animation: spin 0.3s;
  }
}

.parallax_auth {
  max-width: 477px;
  min-height: 80px;
  position: relative;
  top: 50%;
  left: -2%;

  @media (max-width: 1280px) {
    left: 0;
    top: 40%;
  }

  @media (max-width: 780px) {
    left: 10%;
    top: 50%;
  }
}

body.darkMode {
  background-color: var(--dark-color);
}

body.darkMode {
  .info {
    .price {
      border: 2px solid var(--second-dark-color);
      color: var(--gray);
    }
  }
  header {
    .toggleAndBurger {
      .burgerButton {
        svg {
          g {
            stroke: #fff;
          }
        }
      }
    }
  }

  .burgerButton {
    background-image: url('../../assets/icons/burger-light.svg');
  }
}

.darkMode .btn {
  background-color: #4f4f4f;
  box-shadow: 0px 1px 1px 0px #00000040 inset;
}

.darkMode .btn__indicator {
  transform: translateX(24px);
  box-shadow: 0 8px 40px rgba(0, 0, 0, 0.3);
}

.darkMode .btn__icon {
  background-image: url('../../assets/icons/moonIcon.svg');
}
