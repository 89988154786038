.WeWin {
  padding: 40px;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  h2 {
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    text-transform: uppercase;
    width: 100%;
  }
  span {
    color: #2f80ed;
    font-weight: bold;
  }
  button {
    width: 250px;
    height: 60px;
    border-radius: 50px;
    color: #fff;
    margin: 0 auto;

    background-color: #2f80ed;
  }
}
@media (max-width: 720px) {
  .AboutProject {
    padding: 0;
  }
}
