.PasswordTableFrame_root {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 18px;
  justify-content: space-between;

  .header {
    display: flex;
    gap: 24px;
    width: 100%;

    .header_frame {
      display: flex;
      gap: 4px;
      align-items: center;
    }
  }

  .checked,
  .unchecked {
    min-width: 24px;
    min-height: 24px;
    object-fit: contain;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
  }
  .checked {
    height: 24px;
    width: 24px;
    background-image: url('../../assets/icons/yes.svg');
  }
  .unchecked {
    height: 24px;
    width: 24px;
    background-image: url('../../assets/icons/not.svg');
  }

  @media (max-width: 780px) {
    .header {
      flex-direction: column;
      gap: 12px;
    }
  }
}
