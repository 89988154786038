.PasswordForm_root {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  position: relative;
  gap: 50px;
  &-form {
    display: flex;
    gap: 25px;
    flex-direction: column;
  }
  .input_frame {
    margin: 30px auto 0;
    display: flex;
    gap: 24px;
    align-items: start;
    color: #0091c1;

    .input-container {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .coin-gif {
      width: 280px;
      height: auto;
      position: absolute;
      z-index: 10;

      bottom: 0;

      opacity: 0;
      transition: opacity 0.3s;

      animation: coinBounce 0.7s ease-in-out forwards;
    }

    input {
      position: relative;
      z-index: 11;
      background-color: #fff;
      width: 443px;
      transition: all 0.3s;

      height: 62px;
      box-shadow: none !important;
      border-radius: 50px;
      filter: none;
      border: none;
      text-align: center;
      font-size: 16px;
      font-family: 'inter';
      font-weight: 500;

      padding-top: 0px;
      color: #4f4f4f;

      &::placeholder {
        text-transform: uppercase;
        font-size: 16px;
        color: #4f4f4f;
        opacity: 0.3;
      }
    }

    button {
      background-color: transparent;
      z-index: 11;
      padding-right: 20px;
      gap: 10px;
      display: flex;

      transition: all 0.3s;

      justify-content: center;
      &::after {
        height: 20px;
        width: 20px;
        content: url('../../assets/icons/enter-light.svg');
      }
      &:hover {
        &::after {
          content: url('../../assets/icons/enter-hvr.svg');
        }
      }
    }

    @media (max-width: 780px) {
      margin: 0 auto;
      width: 100%;
      flex-direction: column;
      align-items: center;
      gap: 20px;

      input {
        width: 100%;
      }
    }
  }

  .try-count {
    color: #4cb8dc;
    font-weight: 600;
    font-size: 14px;
  }

  .multiplier_container {
    display: flex;
    flex-direction: column;
    background-color: #56ccf250;
    padding: 16px 24px;
    border-radius: 12px;
    gap: 16px;
    color: #828282;
    font-size: 12px;
    font-weight: 500;
    width: 100%;
    align-items: center;
  }

  .multiplier_buttons {
    display: flex;
    gap: 12px;
    width: 100%;

    .multiplier_button {
      width: 100%;
      height: 54px;
      font-size: 28px;
      background-color: #56ccf2;
      color: #fff;
      border: none;

      &.second {
        background-color: #1ab5e9;
      }
      &.third {
        background-color: #0091c1;
      }

      &:hover {
        transform: scale(1.05);
        background-color: #56ccf2 !important;
        color: white !important;
        box-shadow: 0 4px 4px 0 rgba(47, 128, 237, 0.3),
          0 8px 12px 6px rgba(47, 128, 237, 0.2);
      }
    }
  }

  @media (max-width: 780px) {
    gap: 40px;
  }
}

.attempt-counter {
  color: #8a8a8a;
  font-weight: 400;
  font-size: 14px;
  text-align: center;
}

@keyframes coinBounce {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.form-button {
  display: flex;
  margin: 0px auto;
  gap: 60px;
  position: relative;
  width: 100%;
  justify-content: center;

  @media (max-width: 780px) {
    flex-direction: column;
    gap: 30px;
  }
}
.video {
  position: absolute;
  z-index: -1;
  bottom: -40px;

  width: 100vw;
  height: auto;
  background-size: cover;

  @media (max-width: 780px) {
    bottom: initial;
    top: 0;
  }
}

.address {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
  text-wrap: nowrap;
  font-size: 12px;

  .input-container {
    position: relative;
    width: 100%;
  }
  .try-pass {
    position: absolute;
    right: 10px; /* Adjust based on the padding-right of the input */
    top: 50%;
    transform: translateY(-50%);
    background-color: #8382e0; /* Example styling */
    border: none;
    padding: 8px 12px;
    color: white;
    cursor: pointer;
  }

  @media (max-width: 780px) {
    width: 100%;
  }
}

// font-inter font-normal text-base leading-5 bg-salmon-400 px-6 py-2 rounded-lg
.try {
  &-count {
    width: 443px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    color: #333333;
    text-align: center;
    transition: all 0.3s;
  }
  &-rand {
    height: 56px;
    width: 100%;
    background-color: #27ae60;
    border-radius: 100px;
    font-size: 14px;
    color: #fff;
    font-weight: 600;
    font-family: 'inter';
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    gap: 13px;
    &::after {
      height: 21px;
      width: 21px;
      margin-top: -5px;
      transition: all 0.3s;

      content: url('../../assets/icons/list.svg');
    }
    &:hover {
      background-color: #27ae60 !important;
      box-shadow: 0 4px 4px 0 rgba(10, 141, 54, 0.3),
        0 8px 12px 6px rgba(10, 138, 17, 0.2);
      color: #fff !important;
    }
  }
  &-add {
    height: 56px;
    width: 100%;
    background-color: #56ccf2;
    color: white;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    font-weight: 600;
    border: none;
    font-family: 'inter';
    &::after {
      height: 26px;
      width: 26px;
      transition: all 0.3s;
      content: url('../../assets/icons/pluss.svg');
    }
    &:hover {
      background-color: #56ccf2 !important;
      color: white !important;
      box-shadow: 0 4px 4px 0 rgba(47, 128, 237, 0.3),
        0 8px 12px 6px rgba(47, 128, 237, 0.2);
    }

    border-radius: 100px;
  }

  @media (max-width: 780px) {
    &-count {
      width: 100%;
    }
  }
}

body.darkMode {
  .PasswordForm_root {
    .input_frame {
      input {
        background-color: #333333;

        padding-top: 0px;
        color: #828282;
        &::placeholder {
          color: #828282;
        }
      }
      button {
        &::after {
          content: url('../../assets/icons/enter.svg');
        }
        &:hover {
          &::after {
            content: url('../../assets/icons/enter-hvr.svg');
          }
        }
      }
    }
  }
  .try {
    &-count {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 20px;
      color: #c1c1c1;
    }
  }
}
