.accountInfo {
  display: flex;
  flex-direction: column;
  max-width: min-content;
  position: relative;
  min-width: 240px;

  .info {
    position: relative;
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    gap: 10px;
    p {
      color: #333333;
      font-size: 12px;
      transition: all 0.3s;
      text-align: center;
    }
  }

  .dropdownButton {
    display: flex;
    gap: 10px;
    justify-content: center;
    border-radius: 99px;
    background-color: #222222;
    box-shadow: none;
    color: #ffffff;
    align-items: center;
    &::after {
      height: 24px;
      width: 24px;
      transition: all 0.3s;

      content: url('../../assets/icons/TickerDown.svg');
    }
    &:hover {
      scale: 1.05;
    }
  }

  .dropdownButton.true {
    &::after {
      transform: rotate(180deg);
    }
    &:hover {
      background-color: #fff !important;
    }
  }
  .actionList-image {
    height: 24px;
    width: 24px;
    transition: all 0.3s;
  }
  .imageCopy {
    background-image: url('../../assets/icons/copyIcon-dark.svg');
  }
  .imageWallet {
    background-image: url('../../assets/icons/MediaProofingIcon-dart.svg');
  }

  .imageDisable {
    background-image: url('../../assets/icons/ExitIcon-dark.svg');
  }
  .actionList {
    position: absolute;
    margin-top: 60px;
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    justify-content: center;
    align-items: center;
    box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    z-index: 999;

    li {
      border-radius: 8px;
      color: #333333;
      font-size: 15px;
      display: flex;
      width: 100%;
      padding: 12px 18px;

      justify-content: start;
      align-items: center;
      gap: 6px;

      cursor: pointer;
      transition: all 0.3s;
      &:hover {
        color: #fff;
        background-color: #1b6cd9;
        .imageCopy {
          background-image: url('../../assets/icons/copyIcon.svg');
        }

        .imageWallet {
          background-image: url('../../assets/icons/MediaProofingIcon.svg');
        }

        .imageDisable {
          background-image: url('../../assets/icons/ExitIcon.svg');
        }
      }
    }
  }
  button {
    font-size: 14px;
    padding: 14px;
    width: 100%;
    transition: all 0.3s;
    background-color: #ffffff;
    border-radius: 8px;
    border: 2px solid #333333;
    color: #333333;
    box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.2);
    cursor: pointer;
    &:hover {
      color: #fff;
      background-color: #1b6cd9 !important;
    }
  }
  button.active {
    color: #fff;
    background-color: #1b6cd9;
  }
}

@media (max-width: 1279px) {
  .accountInfo {
    display: none;
  }
}

body.darkMode {
  .accountInfo {
    .info {
      p {
        color: #f2f2f2;
      }
    }
    .imageCopy {
      background-image: url('../../assets/icons/copyIcon.svg');
    }

    .imageDisable {
      background-image: url('../../assets/icons/ExitIcon.svg');
    }
    .actionList {
      background-color: #4f4f4f;
      box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.2);
      li {
        color: #f2f2f2;
      }
    }
    button {
      background-color: #4f4f4f;
      color: #f2f2f2;
    }
    button.active {
      color: #fff;
      background-color: #1b6cd9;
    }
  }
  .burgerMenu {
    background-color: var(--dark-color) !important;
  }
}
